import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { NewsFeedState } from './types';

export const tmp = 0;

export const getters: GetterTree<NewsFeedState, RootState> = {
  getAllNewsFeedEntry(state): Record<string, any> {
    return state.news;
  },
  getNewsFeedEntry(state) {
    return (id: string): any => (
      state.news[id]
    );
  },
  getNewsFeedItemToDisplay(state): string {
    return state.newsFeedItemToDisplay;
  },
  getDisplayNewsItem(state): boolean {
    return state.displayNewsFeedItem;
  },
};
