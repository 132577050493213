import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/comments/getters';
import { mutations } from '@/store/comments/mutations';
import { actions } from '@/store/comments/actions';
import { CommentsState } from '@/store/comments/types';

const state: CommentsState = {
  ticketComments: {},
  featureComments: {},
  videoComments: {},
};

export const tmp = 0;

export const comments: Module<CommentsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
