import { ActionTree } from 'vuex';
import {
  firestorePdrPortal, onSnapshot, collection, query, setDoc, doc, deleteDoc,
} from '@/firebase';
import { RootState } from '../types';
import { BookmarksState } from './types';

const tmp = 0;

const actions: ActionTree<BookmarksState, RootState> = {
  fetchUserBookmarkedVideos({ getters, commit }) {
    const uid = getters.getUserUid;
    const bookmarksRef = collection(firestorePdrPortal, `users/${uid}/videoBookmarks`);
    const q = query(bookmarksRef);
    // const querySnapshot = await getDocs(q);

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((document) => {
        // console.log(document.id, ' => ', document.data());
        commit('addUserBookmarkedVideo', {
          id: document.id,
          data: document.data(),
        });
      });
    });
  },
  bookmarkVideo({ getters, dispatch }, videoId: string) {
    const uid = getters.getUserUid;
    const document = doc(firestorePdrPortal, `users/${uid}/videoBookmarks/${videoId}`);
    setDoc(document, {
      createdAtUnix: new Date().getTime(),
    })
      .then(() => {
        console.log('bookmark added');
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
      });
  },
  unbookmarkVideo({ getters, commit, dispatch }, videoId: string) {
    const uid = getters.getUserUid;
    const document = doc(firestorePdrPortal, `users/${uid}/videoBookmarks/${videoId}`);
    deleteDoc(document)
      .then(() => {
        console.log('bookmark removed');
        commit('deleteUserBookmarkedVideo', videoId);
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
      });
  },
  fetchUserBookmarkedArticles({ getters, commit }) {
    const uid = getters.getUserUid;
    const bookmarksRef = collection(firestorePdrPortal, `users/${uid}/articleBookmarks`);
    const q = query(bookmarksRef);

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((document) => {
        // console.log(document.id, ' => ', document.data());
        commit('addUserBookmarkedArticle', {
          id: document.id,
          data: document.data(),
        });
      });
    });
  },
  bookmarkArticle({ getters, dispatch }, articleId: string) {
    const uid = getters.getUserUid;
    const document = doc(firestorePdrPortal, `users/${uid}/articleBookmarks/${articleId}`);
    setDoc(document, {
      createdAtUnix: new Date().getTime(),
    })
      .then(() => {
        console.log('bookmark added');
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
      });
  },
  unbookmarkArticle({ getters, commit, dispatch }, articleId: string) {
    const uid = getters.getUserUid;
    const document = doc(firestorePdrPortal, `users/${uid}/articleBookmarks/${articleId}`);
    deleteDoc(document)
      .then(() => {
        console.log('bookmark removed');
        commit('deleteUserBookmarkedArticle', articleId);
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
      });
  },
  displayBookmark({ commit, getters, dispatch }, payload) {
    const bookmark = payload.type === 'video' ? getters.getVideo(payload.id) : getters.getArticle(payload.id);
    if (payload.type === 'video') {
      dispatch('displayVideo', payload.id);
    } else {
      dispatch('displayKnowledgeArticle', getters.getArticle(payload.id));
    }
    commit('setBookmarkToDisplay', payload.id);
    commit('setBookmarkToDisplayType', payload.type);
    commit('setDisplayBookmark', true);
  },
};

export {
  actions,
  tmp,
};
