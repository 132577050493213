import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/user/getters';
import { mutations } from '@/store/user/mutations';
import { actions } from '@/store/user/actions';
import { UserState } from '@/store/user/types';

const state: UserState = {
  uid: '',
  accountId: '',
  customToken: '',
  idToken: '',
  name: '',
  phone: '',
  email: '',
  userSignedIn: false,
  userSignedInPortal: false,
  idTokenPortal: '',
  customTokenPortal: '',
  lastSignIn: 0,
};

export const tmp = 0;

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
