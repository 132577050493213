var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"#1e75e8","elevate-on-scroll":"","fixed":""}},[_c('v-app-bar-nav-icon',{staticClass:"title",on:{"click":function($event){return _vm.toggle()}}}),_c('v-toolbar-title',{staticClass:"title"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"logo",attrs:{"src":require("@/assets/App Logo blue bg.svg"),"alt":"PDR cloud"},on:{"click":function($event){return _vm.goHome()}}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v(" Startseite ")])]),_vm._v(" Anwender Portal ")],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.newTicket()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-document")])],1)]}}])},[_c('span',[_vm._v("Ticket erstellen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.newFeatureRequest()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus-circle")])],1)]}}])},[_c('span',[_vm._v("Feature-Wunsch übermitteln")])]),_c('v-divider',{attrs:{"vertical":"","dark":"","inset":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToNews()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-newspaper-variant")])],1)]}}])},[_c('span',[_vm._v("Ankündigungen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToTickets()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-ticket-confirmation")])],1)]}}])},[_c('span',[_vm._v("Meine Tickets")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToFeatures()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-format-list-numbered")])],1)]}}])},[_c('span',[_vm._v("Feature-Wünsche")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToKnowledge()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-database-search")])],1)]}}])},[_c('span',[_vm._v("Wissensdatenbank")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToVideo()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-folder-play")])],1)]}}])},[_c('span',[_vm._v("Videothek")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }