import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { KnowledgeState } from './types';

export const tmp = 0;

export const getters: GetterTree<KnowledgeState, RootState> = {
  getAlgoliaSearchKey(state): string {
    return state.algoliaSearchKey;
  },
  getKnowledgeArticleToDisplay(state): Record<string, any> {
    return state.articleToDisplay;
  },
  getKnowledgeDisplayArticle(state): boolean {
    return state.displayArticle;
  },
  getArticle(state) {
    return (id: string): Record<string, any> => (
      state.articles[id]
    );
  },
  getAllArticles(state): Record<string, any> {
    return state.articles;
  },
  getKnowledgeTopics(state): Record<string, any> {
    return state.articleTopics;
  },
  getKnowledgeTopic(state) {
    return (topicId: string): Record<string, any> => (
      state.articleTopics[topicId]
    );
  },
  getKnowledgeTopicArticles(state) {
    return (id: string): [string, any][] => (
      Object.entries(state.articles).filter(
        (value: [string, any]) => value[1].topicId === id,
      )
    );
  },
};
