import Vue from 'vue';
import { MutationTree } from 'vuex';
import { KnowledgeState } from '@/store/knowledge/types';

export const tmp = 0;

export const mutations: MutationTree<KnowledgeState> = {
  setAlgoliaSearchKey(state, value: string) {
    state.algoliaSearchKey = value;
  },
  setKnowledgeArticleToDisplay(state, value: Record<string, any>) {
    state.articleToDisplay = value;
  },
  setKnowledgeDisplayArticle(state, value: boolean) {
    state.displayArticle = value;
  },
  addArticle(state, value) {
    Vue.set(state.articles, value.id, value.data);
  },
  deleteArticle(state, id: string) {
    Vue.delete(state.articles, id);
  },
  addKnowledgeTopic(state, value: Record<string, any>) {
    Vue.set(state.articleTopics, value.id, value.data);
  },
  addKnowledgeTopicIsLoading(state, value: Record<string, any>) {
    Vue.set(state.articleTopics[value.id], 'loading', value.data);
  },
};
