import axios from 'axios';
import { ActionTree } from 'vuex';
import {
  signInWithCustomToken, authPdrTeam, authPdrPortal, setPersistence, browserSessionPersistence,
  onAuthStateChanged,
} from '@/firebase';
import { backendUrl } from '@/store/shared';
import { RootState } from '../types';
import { UserState } from './types';

const tmp = 0;

const actions: ActionTree<UserState, RootState> = {
  async hydrateUserState({ commit, dispatch }, user: any) {
    commit('setUserLastSignIn', user.metadata.lastSignInTime);
    commit('setUserUid', user.uid);
    commit('setUserName', user.displayName);
    commit('setUserEmail', user.email);
    commit('setUserPhone', user.phoneNumber);
    const tokenResult = await user.getIdTokenResult();
    commit('setUserAccountId', tokenResult.claims.account);
    const token = await user.getIdToken();
    commit('setUserIdToken', token);
    commit('setUserSignedIn', true);
    // dispatch further actions
    dispatch('fetchUserDataPdrTeam');
  },
  signInUser({ commit, dispatch }, jwt?: string) {
    onAuthStateChanged(authPdrTeam, (user) => {
      if (user) {
        dispatch('hydrateUserState', authPdrTeam.currentUser);
        dispatch('fetchUserDataPdrTeam');
      } else {
        if (!jwt) {
          commit('setDashboardLoading', false);
          return;
        }
        commit('setUserCustomToken', jwt);
        setPersistence(authPdrTeam, browserSessionPersistence)
          .then(() => {
            signInWithCustomToken(authPdrTeam, jwt)
              .then(async (value) => {
                dispatch('hydrateUserState', value.user);
              })
              .catch((error) => {
                console.log('could not sign in user');
                console.error(error);
                commit('setUserSignedIn', false);
                commit('setDashboardLoading', false);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  },
  fetchUserDataPdrTeam({ dispatch, commit }) {
    if (authPdrTeam.currentUser === null) {
      console.log('user not signed in');
      return;
    }
    // sign in to pdr-portal
    dispatch('signInToPdrPortal');
    // fetch data
    dispatch('fetchAlgoliaSearchKey');
    dispatch('fetchNewsFeed');
    dispatch('fetchTickets');
    dispatch('fetchFeatureRequests');
    dispatch('fetchVideos');
    dispatch('fetchKnowledgeTopics');
    // commit('setDashboardLoading', false);
  },
  fetchUserDataPdrPortal({ dispatch }) {
    if (authPdrTeam.currentUser === null) {
      console.log('user not signed in');
      return;
    }
    dispatch('fetchUserBookmarkedVideos');
    dispatch('fetchUserBookmarkedArticles');
  },
  async signInToPdrPortal({ getters, commit, dispatch }) {
    const header = await getters.getAxiosConfig;
    axios.get(`${backendUrl}/getPortalJwt`, header)
      .then((response) => {
        // console.log(response);
        setPersistence(authPdrPortal, browserSessionPersistence)
          .then(() => {
            signInWithCustomToken(authPdrPortal, response.data.jwt)
              .then(async (value) => {
                console.log('signing in to portal');
                const token = await value.user.getIdToken();
                commit('setUserIdTokenPortal', token);
                commit('setUserSignedInPortal', true);
                dispatch('fetchUserDataPdrPortal');
              })
              .catch((error) => {
                console.log('could not sign in user to portal');
                console.log(error);
                commit('setUserIdTokenPortal', false);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export {
  actions,
  tmp,
};
