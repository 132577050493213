
































































import Vue from 'vue';
import CommentBoxContent from '@/components/Comments/CommentBoxContent.vue';
import CommentEditor from '@/components/Comments/CommentEditor.vue';
// import ImagesContainer from '@/components/UI/ImagesContainer.vue';

export default Vue.extend({
  name: 'CommentBox',
  data: () => ({
    edit: false,
  }),
  props: {
    comment: {
      type: Object,
      required: true,
    },
    commentId: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      default: 1,
    },
  },
  components: {
    // ImagesContainer,
    CommentBoxContent,
    CommentEditor,
  },
  computed: {
    userId: {
      get(): string {
        return this.$store.getters.getUserUid;
      },
    },
  },
  methods: {
    editComment() {
      console.log('edit');
      this.edit = true;
    },
    updateComment(text: string) {
      this.$store.dispatch('updateComment', {
        commentId: this.commentId,
        text,
      });
      this.edit = false;
    },
    deleteComment() {
      this.$store.dispatch('deleteComment', this.commentId);
    },
  },
});
