

























































import Vue from 'vue';

export default Vue.extend({
  name: 'CategoryCard',
  props: {
    category: {
      type: String,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
  },
  computed: {
    subCategories: {
      get(): [string, any][] {
        return this.$store.getters.getKnowledgeTopicArticles(this.categoryId);
      },
    },
    sampleArticles: {
      get(): [string, any][] {
        return this.subCategories.length > 3 ? this.subCategories.slice(0, 3) : this.subCategories;
      },
    },
    topic: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeTopic(this.categoryId);
      },
    },
  },
  created() {
    this.$store.dispatch('fetchTopicArticles', this.categoryId);
  },
  methods: {
    displayArticle(item: any) {
      console.log('display article');
      console.log(item);
      this.$router.push(`/article/${item.id}`);
    },
    goToTopic() {
      this.$router.push(`/knowledge/${this.categoryId}`);
    },
  },
});
