











































































import Vue from 'vue';
import { marked } from 'marked';

export default Vue.extend({
  name: 'KnowledgeArticlePrompt',
  components: {
  },
  data: () => ({
    timeout: 4000,
  }),
  computed: {
    displayKnowledgeArticle: {
      get(): boolean {
        return this.$store.getters.getKnowledgeDisplayArticle;
      },
      set(value: boolean) {
        this.$store.commit('setKnowledgeDisplayArticle', value);
      },
    },
    articleTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeArticleToDisplay;
      },
    },
    createdAt: {
      get(): string {
        return new Date(
          this.articleTodisplay.visibleFromUnix
            ? this.articleTodisplay.visibleFromUnix : this.articleTodisplay.createdAtUnix,
        ).toLocaleDateString('de-DE');
      },
    },
    convertedHtml: {
      get(): string {
        const options: marked.MarkedOptions = {
          breaks: true,
          gfm: true,
          xhtml: true,
        };
        return marked.parse(this.articleTodisplay.html, options);
      },
    },
    bookmarked: {
      get(): boolean {
        return this.$store.getters.isArticleBookmarked(this.articleTodisplay.id);
      },
    },
    bookmarkIcon: {
      get(): string {
        return this.bookmarked ? 'mdi-bookmark' : 'mdi-bookmark-outline';
      },
    },
  },
  methods: {
    closePrompt() {
      this.displayKnowledgeArticle = false;
    },
    bookmarkArticle() {
      if (this.bookmarked) {
        this.$store.dispatch('unbookmarkArticle', this.articleTodisplay.id);
        return;
      }
      this.$store.dispatch('bookmarkArticle', this.articleTodisplay.id);
    },
  },
});
