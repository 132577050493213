import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { CommentsState } from './types';

export const tmp = 0;

export const getters: GetterTree<CommentsState, RootState> = {
  getAllTicketComments(state) {
    return (ticketId: string) => (
      state.ticketComments[ticketId]
    );
  },
  getTicketComment(state) {
    return (ticketId: string, commentId: string) => (
      state.ticketComments[ticketId][commentId]
    );
  },
  getAllFeatureComments(state) {
    return (ticketId: string) => (
      state.featureComments[ticketId]
    );
  },
  getFeatureComment(state) {
    return (ticketId: string, commentId: string) => (
      state.featureComments[ticketId][commentId]
    );
  },
  getAllVideoComments(state) {
    return (ticketId: string) => (
      state.videoComments[ticketId]
    );
  },
  getVideoComment(state) {
    return (ticketId: string, commentId: string) => (
      state.videoComments[ticketId][commentId]
    );
  },
};
