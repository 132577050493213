

























































import Vue from 'vue';
import VideoPlayer from '@/components/Videos/VideoPlayer.vue';
import VideoTags from '@/components/Videos/VideoTags.vue';
import CommentsFeed from '@/components/Comments/CommentsFeed.vue';

export default Vue.extend({
  name: 'VideoScreen',
  components: {
    VideoPlayer,
    VideoTags,
    CommentsFeed,
  },
  computed: {
    videoTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getVideo(this.videoTodisplayId);
      },
    },
    videoTodisplayId: {
      get(): string {
        return this.$route.params.videoId;
      },
    },
    createdAt: {
      get(): string {
        return new Date(
          this.videoTodisplay.visibleFromUnix
            ? this.videoTodisplay.visibleFromUnix : this.videoTodisplay.createdAtUnix,
        ).toLocaleDateString('de-DE');
      },
    },
    bookmarked: {
      get(): boolean {
        return this.$store.getters.isVideoBookmarked(this.videoTodisplayId);
      },
    },
  },
  methods: {
    bookmarkVideo() {
      if (this.bookmarked) {
        this.$store.dispatch('unbookmarkVideo', this.videoTodisplayId);
        return;
      }
      this.$store.dispatch('bookmarkVideo', this.videoTodisplayId);
    },
  },
  created() {
    this.$store.dispatch('fetchVideoComments', this.videoTodisplayId);
  },
});
