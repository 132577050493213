







































import Vue from 'vue';
import FeatureRequestItem from '@/components/FeatureRequestFeed/FeatureRequestItem.vue';

export default Vue.extend({
  name: 'UserFeatureRequestFeed',
  components: {
    FeatureRequestItem,
  },
  computed: {
    features: {
      get(): Record<string, any> {
        return this.$store.getters.getAllFeatureRequests;
      },
    },
    accountId: {
      get(): string {
        return this.$store.getters.getUserAccountId;
      },
    },
  },
  methods: {
    goToMyFeatures() {
      this.$router.push('/myFeatures');
    },
    chipColor(status: 'created' | 'refused' | 'inDiscussion' | 'inDevelopment' | 'released'): string {
      if (status === 'refused') {
        return 'red';
      }
      if (status === 'inDiscussion') {
        return 'yellow';
      }
      if (status === 'inDevelopment') {
        return 'orange';
      }
      if (status === 'released') {
        return 'green';
      }
      return 'grey';
    },
  },
  created() {
    if (!this.features) {
      this.$store.dispatch('fetchUserFeatureRequests');
    }
  },
});
