import Vue from 'vue';
import { MutationTree } from 'vuex';
import { TicketsState } from '@/store/tickets/types';

export const tmp = 0;

export const mutations: MutationTree<TicketsState> = {
  addTicket(state, value: Record<string, any>) {
    Vue.set(state.tickets, value.id, value.data);
  },
  setDisplayTicket(state, value: boolean) {
    state.displayTicket = value;
  },
  setTicketToDisplay(state, value: string) {
    state.ticketToDisplay = value;
  },
  setDisplayNewTicketPrompt(state, value: boolean) {
    state.displayNewTicketPrompt = value;
  },
  setTicketSending(state, value: boolean) {
    state.ticketSending = value;
  },
  setNewTicketTopic(state, value: string) {
    state.newTicketTopic = value;
  },
  setNewTicketInfo(state, value: string) {
    state.newTicketInfo = value;
  },
  setNewTicketAttachments(state, value: File[]) {
    state.newTicketAttachments = value;
  },
  addNewTicketAttachment(state, value: File) {
    state.newTicketAttachments.push(value);
  },
  setAttachmentToDisplay(state, value: string) {
    state.attachmentToDisplay = value;
  },
  setDisplayAttachment(state, value: boolean) {
    state.displayAttachment = value;
  },
  // setTicketComment(state, value: Record<string, any>) {
  //   Vue.set(state.ticketComments[value.ticketId], value.id, value.data);
  // },
};
