











































import Vue from 'vue';
import ImagesContainer from '@/components/UI/ImagesContainer.vue';

export default Vue.extend({
  name: 'CommentBoxContent',
  props: {
    comment: {
      type: Object,
      required: true,
    },
    col: {
      type: Number,
      default: 1,
    },
  },
  components: {
    ImagesContainer,
  },
  computed: {
    postedAt: {
      get(): string {
        return new Date(this.comment.createdAtUnix).toLocaleString('de-DE');
      },
    },
    posterBadge: {
      get(): string {
        const names = this.comment.createdByName.split(' ');
        return `${names[0].charAt(0).toUpperCase()}${
          names.length > 1 ? names[1].charAt(0).toUpperCase() : ''
        }`;
      },
    },
  },
  methods: {
    getBadgeColor(ligthness: number): string {
      let hash = 0;
      const name = this.comment.createdByName !== undefined ? this.comment.createdByName : 'User';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.comment.createdByName.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h}, 30%, ${ligthness}%)`;
    },
  },
});
