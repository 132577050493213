import { ActionTree } from 'vuex';
import axios from 'axios';
import {
  firestorePdrTeam, collection, query, where, onSnapshot, orderBy, doc, getDoc,
} from '@/firebase';
import { backendUrl, uploadFiles } from '@/store/shared';
import { RootState } from '../types';
import { TicketsState } from './types';

const tmp = 0;

const actions: ActionTree<TicketsState, RootState> = {
  async sendTicket({ getters, commit, dispatch }, payload: Record<string, any>) {
    commit('setTicketSending', true);
    const attachments = await uploadFiles(
      payload.attachments,
      getters.getUserUid,
      getters.getUserAccountId,
    );
    const headers = await getters.getAxiosConfig;
    axios.post(`${backendUrl}/ticketApi/ticket`, {
      attachments,
      topic: payload.topic,
      clientInfo: payload.clientInfo,
    }, headers)
      .then((response) => {
        // console.log(response);
        commit(
          'setSnackbarMessage',
          'Ticket wurde verschickt, wir werden uns bald mit Ihnen in Verbindung setzen',
        );
        commit('setTicketSending', false);
        commit('setDisplayNewTicketPrompt', false);
        commit('setShowSnackbar', true);
      })
      .catch((error) => {
        commit('setTicketSending', false);
        commit('setDisplayNewTicketPrompt', false);
        dispatch('showSnackbarError', 'Während Ihrer Anfrage ist ein Fehler aufgetreten');
      });
  },
  async fetchTickets({ getters, commit }) {
    const ticketsRef = collection(firestorePdrTeam, 'tickets');
    const q = query(
      ticketsRef,
      where('clientId', '==', getters.getUserAccountId),
      orderBy('createdAtUnix', 'desc'),
    );
    // const querySnapshot = await getDocs(q);

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((docu) => {
        // console.log(doc.id, ' => ', doc.data());
        commit('addTicket', {
          id: docu.id,
          data: docu.data(),
        });
      });
    });
  },
  displayTicket({ commit }, ticketId: string) {
    commit('setTicketToDisplay', ticketId);
    commit('setDisplayTicket', true);
  },
  async fetchTicket({ commit }, id: string) {
    const docRef = doc(firestorePdrTeam, 'tickets', id);
    getDoc(docRef)
      .then((docSnapshot) => {
        commit('addTicket', {
          id: docSnapshot.id,
          data: docSnapshot.data(),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export {
  actions,
  tmp,
};
