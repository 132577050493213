import Vue from 'vue';
import { MutationTree } from 'vuex';
import { CommentsState } from '@/store/comments/types';

export const tmp = 0;

export const mutations: MutationTree<CommentsState> = {
  setTicketComment(state, value: Record<string, any>) {
    if (state.ticketComments[value.ticketId]) {
      Vue.set(state.ticketComments[value.ticketId], value.id, value.data);
    } else {
      Vue.set(state.ticketComments, value.ticketId, {});
      Vue.set(state.ticketComments[value.ticketId], value.id, value.data);
    }
  },
  setFeatureComment(state, value: Record<string, any>) {
    if (state.featureComments[value.featureId]) {
      Vue.set(state.featureComments[value.featureId], value.id, value.data);
    } else {
      Vue.set(state.featureComments, value.featureId, {});
      Vue.set(state.featureComments[value.featureId], value.id, value.data);
    }
  },
  setVideoComment(state, value: Record<string, any>) {
    if (state.videoComments[value.videoId]) {
      Vue.set(state.videoComments[value.videoId], value.id, value.data);
    } else {
      Vue.set(state.videoComments, value.videoId, {});
      Vue.set(state.videoComments[value.videoId], value.id, value.data);
    }
  },
};
