















































































import Vue from 'vue';

export default Vue.extend({
  name: 'NewFeatureRequestPrompt',
  data: () => ({
    timeout: 4000,
  }),
  methods: {
    clear() {
      this.topic = '';
      this.info = '';
      this.attachments = [];
    },
    submit() {
      const payload = {
        topic: this.topic,
        info: this.info,
        attachments: this.attachments,
      };
      console.log('submited');
      this.$store.dispatch('sendFeatureRequest', payload);
    },
    uploadFile(event: any) {
      this.attachments.push(event[0]);
    },
  },
  computed: {
    newFeature: {
      get(): boolean {
        return this.$store.getters.getDisplayNewFeaturePrompt;
      },
      set(value: boolean) {
        this.$store.commit('setDisplayNewFeaturePrompt', value);
      },
    },
    topic: {
      get(): string {
        return this.$store.getters.getNewFeatureTopic;
      },
      set(value: string) {
        this.$store.commit('setNewFeatureTopic', value);
      },
    },
    info: {
      get(): string {
        return this.$store.getters.getNewFeatureInfo;
      },
      set(value: string) {
        this.$store.commit('setNewFeatureInfo', value);
      },
    },
    attachments: {
      get(): File[] {
        return this.$store.getters.getNewFeatureAttachments;
      },
      set(value: File[]) {
        this.$store.commit('setNewFeatureAttachments', value);
      },
    },
    featureSending: {
      get(): boolean {
        return this.$store.getters.getNewFeaturSending;
      },
    },
    snackbar: {
      get(): boolean {
        return this.$store.getters.getShowSnackbar;
      },
      set(value: boolean) {
        this.$store.commit('setShowSnackbar', value);
      },
    },
    snackbarMessage: {
      get(): string {
        return this.$store.getters.getSnackbarMessage;
      },
    },
  },
});
