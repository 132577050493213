























import Vue from 'vue';
import ArticleCard from '@/components/KnowledgeBase/ArticleCard.vue';

export default Vue.extend({
  name: 'KnowledgeArticlesList',
  components: {
    ArticleCard,
  },
  computed: {
    articles: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeTopicArticles(this.id);
      },
    },
    topic: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeTopic(this.id);
      },
    },
    id: {
      get(): string {
        return this.categoryId ? this.categoryId : this.$route.params.topicId;
      },
    },
    title: {
      get(): string {
        return this.topic.name;
      },
    },
  },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  created() {
    this.$store.dispatch('fetchKnowledgeTopics');
    this.$store.dispatch('fetchTopicArticles', this.id);
  },
});
