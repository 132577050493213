





























import Vue from 'vue';

export default Vue.extend({
  name: 'ArticleCard',
  components: {},
  props: {
    article: {
      required: true,
      type: Object,
    },
  },
  methods: {
    goToArticle() {
      this.$router.push(`/article/${this.article.id}`);
    },
  },
});
