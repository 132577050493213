





































import Vue from 'vue';
import FeatureRequestItem from '@/components/FeatureRequestFeed/FeatureRequestItem.vue';

export default Vue.extend({
  name: 'FeatureRequestFeed',
  components: {
    FeatureRequestItem,
  },
  computed: {
    features: {
      get(): Record<string, any> {
        return this.$store.getters.getAllFeatureRequests;
      },
    },
  },
  methods: {
    goToMyFeatures() {
      this.$router.push('/myFeatures');
    },
  },
});
