






















import Vue from 'vue';
import { marked } from 'marked';

export default Vue.extend({
  name: 'NewsScreen',
  computed: {
    newsFeedItemTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getNewsFeedEntry(this.newsFeedItemTodisplayId);
      },
    },
    newsFeedItemTodisplayId: {
      get(): string {
        return this.$route.params.newsId;
      },
    },
    createdAt: {
      get(): string {
        return new Date(
          this.newsFeedItemTodisplay.visibleFromUnix
            ? this.newsFeedItemTodisplay.visibleFromUnix : this.newsFeedItemTodisplay.createdAtUnix,
        ).toLocaleDateString('de-DE');
      },
    },
    convertedHtml: {
      get(): string {
        const options: marked.MarkedOptions = {
          breaks: true,
          gfm: true,
          xhtml: true,
        };
        return marked.parse(this.newsFeedItemTodisplay.info, options);
      },
    },
    chipColor: {
      get(): string {
        return this.newsFeedItemTodisplay.type === 'general' ? 'grey' : 'green';
      },
    },
  },
});
