






















import Vue from 'vue';
import NewsFeedItem from '@/components/NewsFeed/NewsFeedItem.vue';

export default Vue.extend({
  name: 'NewsFeed',
  components: {
    NewsFeedItem,
  },
  computed: {
    news: {
      get(): Record<string, any> {
        return this.$store.getters.getAllNewsFeedEntry;
      },
    },
    displayNewsFeedItem: {
      get(): boolean {
        return this.$store.getters.getDisplayNewsItem;
      },
    },
  },
});
