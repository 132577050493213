















































































import Vue from 'vue';

export default Vue.extend({
  name: 'NewTicketPrompt',
  data: () => ({
    timeout: 4000,
    // topic: '',
    // clientInfo: '',
    // attachments: [] as any[],
  }),
  methods: {
    clear() {
      this.topic = '';
      this.info = '';
      this.attachments = [];
    },
    submit() {
      const payload = {
        topic: this.topic,
        clientInfo: this.info,
        attachments: this.attachments,
      };
      console.log('submited');
      this.$store.dispatch('sendTicket', payload);
    },
    uploadFile(event: any) {
      this.attachments.push(event[0]);
    },
  },
  computed: {
    newTicket: {
      get(): boolean {
        return this.$store.getters.getDisplayNewTicketPrompt;
      },
      set(value: boolean) {
        this.$store.commit('setDisplayNewTicketPrompt', value);
      },
    },
    topic: {
      get(): string {
        return this.$store.getters.getNewTicketTopic;
      },
      set(value: string) {
        this.$store.commit('setNewTicketTopic', value);
      },
    },
    info: {
      get(): string {
        return this.$store.getters.getNewTicketInfo;
      },
      set(value: string) {
        this.$store.commit('setNewTicketInfo', value);
      },
    },
    attachments: {
      get(): File[] {
        return this.$store.getters.getNewTicketAttachments;
      },
      set(value: File[]) {
        this.$store.commit('setNewTicketAttachments', value);
      },
    },
    ticketSending: {
      get(): boolean {
        return this.$store.getters.getTicketSending;
      },
    },
    snackbar: {
      get(): boolean {
        return this.$store.getters.getShowSnackbar;
      },
      set(value: boolean) {
        this.$store.commit('setShowSnackbar', value);
      },
    },
    snackbarMessage: {
      get(): string {
        return this.$store.getters.getSnackbarMessage;
      },
    },
  },
});
