// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFirestore, Firestore, onSnapshot, doc, collection, query, where, getDocs,
  orderBy, setDoc, deleteDoc, getDoc,
} from 'firebase/firestore';
import {
  getAuth, Auth, signInWithCustomToken, setPersistence, browserSessionPersistence,
  browserLocalPersistence, onAuthStateChanged,
} from 'firebase/auth';
import {
  getStorage, FirebaseStorage, ref, uploadBytes, getDownloadURL,
} from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfigPdrTeam = {
  apiKey: 'AIzaSyCM6ZhCzVhHe8zoqt5JalVen5fTN4uor1Q',
  authDomain: 'pdr-team.firebaseapp.com',
  projectId: 'pdr-team',
  storageBucket: 'pdr-team.appspot.com',
  messagingSenderId: '791555181035',
  appId: '1:791555181035:web:d4c92ffaacd0fca1ed31ae',
};

const firebaseConfigPdrPortal = {
  apiKey: 'AIzaSyA3Lxbv03RnhsaJCtka3-xd0clBYDBpSJ8',
  authDomain: 'pdr-portal-349114.firebaseapp.com',
  projectId: 'pdr-portal-349114',
  storageBucket: 'pdr-portal-349114.appspot.com',
  messagingSenderId: '840939649498',
  appId: '1:840939649498:web:b60b9044bf4020b74428e3',
};

// Initialize pdr-team Firebase
const appPdrTeam: FirebaseApp = initializeApp(firebaseConfigPdrTeam);

const authPdrTeam: Auth = getAuth(appPdrTeam);

const firestorePdrTeam: Firestore = getFirestore(appPdrTeam);

const storagePdrTeam: FirebaseStorage = getStorage(appPdrTeam, 'gs://pdr-portal-uploads');

// Initialize pdr-portal Firebase

const appPdrPortal: FirebaseApp = initializeApp(firebaseConfigPdrPortal, 'portal');

const authPdrPortal: Auth = getAuth(appPdrPortal);

const firestorePdrPortal: Firestore = getFirestore(appPdrPortal);

export {
  appPdrTeam,
  appPdrPortal,
  // auth
  authPdrTeam,
  authPdrPortal,
  signInWithCustomToken,
  setPersistence,
  onAuthStateChanged,
  browserSessionPersistence,
  browserLocalPersistence,
  // firestore
  firestorePdrTeam,
  firestorePdrPortal,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  setDoc,
  deleteDoc,
  // storage
  storagePdrTeam,
  ref,
  uploadBytes,
  getDownloadURL,
};
