

















































import Vue from 'vue';
import TicketPromptMeta from '@/components/TicketsFeed/TicketPromptMeta.vue';
import ImageOverlay from '@/components/UI/ImageOverlay.vue';
import CommentsFeed from '@/components/Comments/CommentsFeed.vue';
import ImagesContainer from '@/components/UI/ImagesContainer.vue';

export default Vue.extend({
  name: 'TicketScreen',
  data: () => ({
    attachmentsUrl: [] as string[],
  }),
  components: {
    ImageOverlay,
    CommentsFeed,
    ImagesContainer,
    TicketPromptMeta,
  },
  computed: {
    ticketTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getTicket(this.ticketTodisplayId);
      },
    },
    ticketTodisplayId: {
      get(): string {
        return this.$route.params.ticketId;
      },
    },
    chipColor: {
      get(): string {
        return this.ticketTodisplay.done ? 'green' : 'orange';
      },
    },
  },
  methods: {
    localStringDate(timestamp: number): string {
      return new Date(timestamp).toLocaleString('de-DE');
    },
    displayImg(img: string) {
      this.$store.commit('setAttachmentToDisplay', img);
      this.$store.commit('setDisplayAttachment', true);
    },
    loadComments() {
      this.$store.dispatch('fetchTicketComments', this.ticketTodisplayId);
    },
  },
  created() {
    this.loadComments();
  },
  beforeCreate() {
    this.$store.dispatch('fetchTicket', this.$route.params.ticketId);
  },
});
