import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { TicketsState, Ticket } from './types';

export const tmp = 0;

export const getters: GetterTree<TicketsState, RootState> = {
  getAllTickets(state): Record<string, Ticket> {
    return state.tickets;
  },
  getTicket(state) {
    return (id: string): Ticket => (
      state.tickets[id]
    );
  },
  getDisplayTicket(state): boolean {
    return state.displayTicket;
  },
  getTicketToDisplay(state): string {
    return state.ticketToDisplay;
  },
  getDisplayNewTicketPrompt(state): boolean {
    return state.displayNewTicketPrompt;
  },
  getTicketSending(state): boolean {
    return state.ticketSending;
  },
  getNewTicketTopic(state): string {
    return state.newTicketTopic;
  },
  getNewTicketInfo(state): string {
    return state.newTicketInfo;
  },
  getNewTicketAttachments(state): File[] {
    return state.newTicketAttachments;
  },
  getAttachmentToDisplay(state): string {
    return state.attachmentToDisplay;
  },
  getDisplayAttachment(state): boolean {
    return state.displayAttachment;
  },
  // getTicketAllComments(state) {
  //   return (id: string): Record<string, any> => (
  //     state.ticketComments[id]
  //   );
  // },
  // getTicketComment(state) {
  //   return (ticketId: string, commentId: string) => (
  //     state.ticketComments[ticketId][commentId]
  //   );
  // },
};
