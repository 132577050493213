import { ActionTree } from 'vuex';
import axios from 'axios';
import {
  firestorePdrTeam, collection, query, where, onSnapshot, orderBy,
} from '@/firebase';
import { backendUrl, uploadFiles } from '@/store/shared';
import { RootState } from '../types';
import { CommentsState } from './types';

const tmp = 0;

const actions: ActionTree<CommentsState, RootState> = {
  async fetchTicketComments({ commit, getters }, ticketId: string) {
    const commentsRef = collection(firestorePdrTeam, 'comments');
    const q = query(
      commentsRef,
      where('deleted', '==', false),
      where('parentType', '==', 'ticket'),
      where('parentId', '==', ticketId),
      where('clientAccount', '==', getters.getUserAccountId),
      orderBy('createdAtUnix', 'asc'),
    );

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log('comment', ticketId);
        // console.log(doc.id, ' => ', doc.data());
        commit('setTicketComment', {
          ticketId,
          id: doc.id,
          data: doc.data(),
        });
      });
    });
  },
  async fetchFeatureComments({ commit }, featureId: string) {
    const commentsRef = collection(firestorePdrTeam, 'comments');
    const q = query(
      commentsRef,
      where('deleted', '==', false),
      where('parentType', '==', 'featureRequest'),
      where('parentId', '==', featureId),
      orderBy('createdAtUnix', 'asc'),
    );

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log('comment of feature:', featureId);
        // console.log(doc.id, ' => ', doc.data());
        commit('setFeatureComment', {
          featureId,
          id: doc.id,
          data: doc.data(),
        });
      });
    });
  },
  async fetchVideoComments({ commit }, videoId: string) {
    const commentsRef = collection(firestorePdrTeam, 'comments');
    const q = query(
      commentsRef,
      where('deleted', '==', false),
      where('parentType', '==', 'video'),
      where('parentId', '==', videoId),
      orderBy('createdAtUnix', 'asc'),
    );

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log('comment of video:', videoId);
        // console.log(doc.id, ' => ', doc.data());
        commit('setVideoComment', {
          videoId,
          id: doc.id,
          data: doc.data(),
        });
      });
    });
  },
  async sendComment({ getters, dispatch }, payload: any) {
    // console.log('sendComment');
    // console.log(payload);
    const attachments = await uploadFiles(
      payload.attachments,
      getters.getUserUid,
      getters.getUserAccountId,
    );
    const headers = await getters.getAxiosConfig;
    const url = `${backendUrl}/commentApi/comment`;
    axios.post(url, {
      text: payload.text,
      parentType: payload.parentType,
      parentId: payload.parentId,
      attachments,
    }, headers)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
        console.log(error);
      });
  },
  async deleteComment({ getters, dispatch }, commentId: string) {
    console.log('deleting comment');
    const headers = await getters.getAxiosConfig;
    const url = `${backendUrl}/commentApi/comment/${commentId}`;
    axios.delete(url, headers)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
      });
  },
  async updateComment({ getters, dispatch }, payload: any) {
    const headers = await getters.getAxiosConfig;
    const url = `${backendUrl}/commentApi/comment/${payload.commentId}`;
    axios.post(url, {
      text: payload.text,
    }, headers)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        dispatch('showSnackbarError', 'Anfrage Könnte nicht gesendet werden');
      });
  },
};

export {
  actions,
  tmp,
};
