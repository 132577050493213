import Vue from 'vue';
import { MutationTree } from 'vuex';
import { NewsFeedState } from '@/store/news/types';

export const tmp = 0;

export const mutations: MutationTree<NewsFeedState> = {
  addNewsFeedEntry(state, value: Record<string, any>) {
    Vue.set(state.news, value.id, value.data);
  },
  setNewsFeedItemToDisplay(state, value: string) {
    state.newsFeedItemToDisplay = value;
  },
  setDisplayNewsItem(state, value: boolean) {
    state.displayNewsFeedItem = value;
  },
};
