import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/videos/getters';
import { mutations } from '@/store/videos/mutations';
import { actions } from '@/store/videos/actions';
import { VideosState } from '@/store/videos/types';

const state: VideosState = {
  videos: {},
  displayVideo: false,
  videoToDisplay: '',
};

export const tmp = 0;

export const videos: Module<VideosState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
