
































































import Vue from 'vue';
import RecentItem from '@/components/RecentItems/RecentItem.vue';

export default Vue.extend({
  name: 'RecentTicketsBox',
  data: () => ({
    noItemsText: 'Keine Tickets',
    newItemsCount: 2,
    header: 'Tickets',
  }),
  components: {
    RecentItem,
  },
  props: {
  },
  computed: {
    tickets: {
      get(): Record<string, any> {
        return this.$store.getters.getAllTickets;
      },
    },
    lastSignIn: {
      get(): number {
        return this.$store.getters.getUserLastSignIn;
      },
    },
    newTickets: {
      get(): Record<string, any> {
        return this.getNewTickets();
      },
    },
  },
  methods: {
    goToTickets() {
      this.$router.push('/ticket');
    },
    displayTicket(id: string) {
      this.$router.push(`/ticket/${id}`);
    },
    getNewTickets(): Record<string, any> {
      const newNews: Record<string, any> = {};
      const now = new Date();
      now.setDate(now.getDate() - 3);
      Object.entries(this.tickets).forEach((value) => {
        if (value[1].updatedAtUnix > now) {
          // eslint-disable-next-line prefer-destructuring
          newNews[value[0]] = value[1];
        }
      });
      return newNews;
    },
  },
});
