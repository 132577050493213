
































import Vue from 'vue';
import BookmarkItem from '@/components/Bookmarks/BookmarkItem.vue';

export default Vue.extend({
  name: 'BookmarkFeed',
  components: {
    BookmarkItem,
  },
  props: {
    items: {
      type: Object as () => Record<string, any>,
      required: true,
    },
    itemsType: {
      type: String,
      default: 'article',
    },
    header: {
      type: String,
      required: true,
    },
  },
  computed: {
    videos: {
      get(): Record<string, any> {
        return this.fetchVideos(this.items);
      },
    },
    articles: {
      get(): Record<string, any> {
        return this.fetchArticle(this.items);
      },
    },
  },
  methods: {
    fetchVideos(videoIds: Record<string, any>): Record<string, any> {
      const videos: Record<string, any> = {};
      Object.entries(videoIds).forEach((value) => {
        videos[value[0]] = this.$store.getters.getVideo(value[0]);
      });
      return videos;
    },
    fetchArticle(articleIds: Record<string, any>): Record<string, any> {
      const articles: Record<string, any> = {};
      Object.entries(articleIds).forEach((value) => {
        articles[value[0]] = this.$store.getters.getArticle(value[0]);
      });
      return articles;
    },
    displayItem(id: string) {
      // console.log(id);
      this.$emit('display', id);
    },
  },
});
