import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { BookmarksState, BookmarkType } from './types';

export const tmp = 0;

export const getters: GetterTree<BookmarksState, RootState> = {
  getUserBookmarkedVideosId(state): Record<string, any> {
    return state.bookmarkedVideos;
  },
  isVideoBookmarked(state) {
    return (id: string): boolean => (
      state.bookmarkedVideos[id] !== undefined
    );
  },
  getUserBookmarkedArticlesId(state): Record<string, any> {
    return state.bookmarkedArticles;
  },
  isArticleBookmarked(state) {
    return (id: string): boolean => (
      state.bookmarkedArticles[id] !== undefined
    );
  },
  getDisplayBookmark(state): boolean {
    return state.displayBookmark;
  },
  getBookmarkToDisplay(state): Record<string, any> {
    return state.bookmarkToDisplay;
  },
  getBookmarkToDisplayId(state): string {
    return state.bookmarkToDisplayId;
  },
  getBookmarkToDisplayType(state): BookmarkType {
    return state.bookmarkToDisplayType;
  },
};
