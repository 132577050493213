import { Module } from 'vuex';
import { RootState } from '../types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { BookmarksState } from './types';

const state: BookmarksState = {
  bookmarkedVideos: {},
  bookmarkedArticles: {},
  bookmarkToDisplay: {},
  bookmarkToDisplayId: '',
  bookmarkToDisplayType: '',
  displayBookmark: false,
};

export const tmp = 0;

export const bookmarks: Module<BookmarksState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
