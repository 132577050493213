












































import Vue from 'vue';

export default Vue.extend({
  name: 'VideoCard',
  components: {},
  props: {
    video: {
      type: Object,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    createdAt: {
      get(): string {
        return new Date(this.video.visibleFromUnix
          ? this.video.visibleFromUnix : this.video.createdAtUnix).toLocaleDateString('de-DE');
      },
    },
    bookmarked: {
      get(): boolean {
        return this.$store.getters.isVideoBookmarked(this.videoId);
      },
    },
  },
  methods: {
    displayVideo() {
      // this.$store.dispatch('displayVideo', this.videoId);
      this.$router.push(`video/${this.videoId}`);
    },
    bookmarkVideo() {
      if (this.bookmarked) {
        this.$store.dispatch('unbookmarkVideo', this.videoId);
        return;
      }
      this.$store.dispatch('bookmarkVideo', this.videoId);
    },
  },
});
