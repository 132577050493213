































import Vue from 'vue';

export default Vue.extend({
  name: 'BookmarkItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  computed: {
    createdAt: {
      get(): string {
        return new Date(this.item.visibleFromUnix
          ? this.item.visibleFromUnix : this.item.createdAtUnix).toLocaleDateString('de-DE');
      },
    },
    chipColor: {
      get(): string {
        return this.item.type === 'general' ? 'grey light' : 'green';
      },
    },
  },
  methods: {
    diaplayBookmark() {
      this.$emit('display');
    },
    deleteBookmark() {
      if (this.itemType === 'article') {
        this.$store.dispatch('unbookmarkArticle', this.itemId);
      } else {
        this.$store.dispatch('unbookmarkVideo', this.itemId);
      }
    },
  },
});
