import { storagePdrTeam, ref, uploadBytes } from '@/firebase';

const backendUrl = 'https://europe-west3-pdr-portal-349114.cloudfunctions.net';
// const backendUrl = 'http://localhost:5000';
const tmp = 0;

async function uploadFiles(files: File[], uid: string, accountId: string): Promise<string[]> {
  const attachments: string[] = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    // console.log(`file: ${file}`);
    // console.log(file.name);
    const storageRef = ref(storagePdrTeam, `userUploads/${accountId}/${uid}/${file.name}`);
    // eslint-disable-next-line no-await-in-loop
    const result = await uploadBytes(storageRef, file);
    attachments.push(result.ref.fullPath);
  }
  return attachments;
}

export { backendUrl, tmp, uploadFiles };
