import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/features/getters';
import { mutations } from '@/store/features/mutations';
import { actions } from '@/store/features/actions';
import { FeaturesState } from '@/store/features/types';

const state: FeaturesState = {
  displayFeature: false,
  featureTodDisplay: '',
  displayNewFeaturePrompt: false,
  newFeatureTopic: '',
  newFeatureInfo: '',
  newFeatureAttachments: [],
  newFeatureSending: false,
  featureAttachmentToDisplay: '',
  displayFeatureAttachment: false,
  features: {},
  accountVotes: {},
};

export const tmp = 0;

export const features: Module<FeaturesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
