

























import Vue from 'vue';

export default Vue.extend({
  name: 'ImageOverlay',
  components: {
  },
  computed: {
    imageToDisplay: {
      get(): string {
        return this.$store.getters.getAttachmentToDisplay;
      },
    },
    display: {
      get(): boolean {
        return this.$store.getters.getDisplayAttachment;
      },
      set(value: boolean) {
        this.$store.commit('setDisplayAttachment', value);
      },
    },
  },
});
