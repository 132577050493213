import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { VideosState } from './types';

export const tmp = 0;

export const getters: GetterTree<VideosState, RootState> = {
  getAllVideos(state): Record<string, any> {
    return state.videos;
  },
  getVideo(state) {
    return (id: string): any => (
      state.videos[id]
    );
  },
  getVideoToDisplay(state): string {
    return state.videoToDisplay;
  },
  getDisplayVideo(state): boolean {
    return state.displayVideo;
  },
};
