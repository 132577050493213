


























import Vue from 'vue';

export default Vue.extend({
  name: 'CommentTextBox',
  data: () => ({
    comment: '',
    attachments: [] as File[],
  }),
  props: {
    parentId: {
      type: String,
      required: true,
    },
    parentType: {
      type: String,
      default: 'ticket',
    },
  },
  computed: {},
  components: {},
  methods: {
    sendComment() {
      console.log('sending comment');
      const payload = {
        text: this.comment,
        parentId: this.parentId,
        parentType: this.parentType,
        attachments: this.attachments,
      };
      this.$store.dispatch('sendComment', payload);
      this.comment = '';
      this.attachments = [];
    },
    uploadFile(event: any) {
      this.attachments.push(event[0]);
    },
  },
});
