import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/tickets/getters';
import { mutations } from '@/store/tickets/mutations';
import { actions } from '@/store/tickets/actions';
import { TicketsState } from '@/store/tickets/types';

const state: TicketsState = {
  displayTicket: false,
  displayNewTicketPrompt: false,
  ticketSending: false,
  ticketToDisplay: '',
  newTicketTopic: '',
  newTicketInfo: '',
  newTicketAttachments: [],
  showTicketSnackbar: false,
  ticketSnackbarMessage: '',
  attachmentToDisplay: '',
  displayAttachment: false,
  // ticketComments: {},
  tickets: {},
  //   first: {
  //     topic: 'Shitty app',
  //     ticketId: '123',
  //     clientInfo: 'something went wrong',
  //     clientId: 'clientId',
  //     clientUserName: 'someone',
  //     done: true,
  //     answer: 'restart your computer',
  //     attachments: [],
  //     createdAtUnix: 1651664602000,
  //   },
  //   second: {
  //     topic: 'Shitty app 2',
  //     ticketId: '1234',
  //     clientInfo: 'something went wrong',
  //     clientId: 'clientId',
  //     clientUserName: 'someone',
  //     done: false,
  //     answer: '',
  //     attachments: [],
  //     createdAtUnix: 1651666602000,
  //   },
  // },
};

export const tmp = 0;

export const tickets: Module<TicketsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
