
























import Vue from 'vue';
import TheMenu from '@/components/TheMenu/TheMenu.vue';
import TheAppBar from '@/components/TheMenu/TheAppBar.vue';
import ErrorScreen from '@/components/ErrorScreen/ErrorScreen.vue';
import NewTicketPrompt from '@/components/TicketsFeed/NewTicketPrompt.vue';
import NewFeatureRequestPrompt from '@/components/FeatureRequestFeed/NewFeatureRequestPrompt.vue';

export default Vue.extend({
  name: 'App',
  components: {
    TheMenu,
    TheAppBar,
    ErrorScreen,
    NewTicketPrompt,
    NewFeatureRequestPrompt,
  },
  computed: {
    userSignedIn: {
      get(): boolean {
        return this.$store.getters.getUserSignedIn;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getDashboardLoading;
      },
    },
  },
  data: () => ({}),
  beforeCreate() {
    this.$store.dispatch('signInUser', this.$route.query.jwt);
  },
});
