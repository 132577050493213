












































import Vue from 'vue';
import CategoryCard from '@/components/KnowledgeBase/CategoryCard.vue';
// import KnowledgeArticlePrompt from '@/components/KnowledgeBase/KnowledgeArticlePrompt.vue';
// import KnowledgeSearchWidget from '@/components/KnowledgeBase/KnowledgeSearchWidget.vue';

export default Vue.extend({
  name: 'KnowledgeBaseCategories',
  components: {
    CategoryCard,
    // KnowledgeArticlePrompt,
    // KnowledgeSearchWidget,
  },
  computed: {
    categories: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeTopics;
      },
    },
    articleTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeArticleToDisplay;
      },
    },
  },
  methods: {
    search() {
      this.$router.push('/knowledge/search');
    },
  },
});
