import Vue from 'vue';
import Vuex from 'vuex';
import { user } from './user/index';
import { tickets } from './tickets/index';
import { dashboard } from './dashboard/index';
import { newsFeed } from './news/index';
import { features } from './features/index';
import { comments } from './comments/index';
import { knowledge } from './knowledge/index';
import { videos } from './videos/index';
import { bookmarks } from './bookmarks/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    tickets,
    dashboard,
    newsFeed,
    features,
    comments,
    knowledge,
    videos,
    bookmarks,
  },
});
