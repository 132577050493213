import { MutationTree } from 'vuex';
import { UserState } from '@/store/user/types';

export const tmp = 0;

export const mutations: MutationTree<UserState> = {
  initUserState(state) {
    state.uid = '';
    state.name = '';
    state.phone = '';
    state.email = '';
  },
  setUserAccountId(state, value: string) {
    state.accountId = value;
  },
  setUserUid(state, value: string) {
    state.uid = value;
  },
  setUserName(state, value: string) {
    state.name = value;
  },
  setUserPhone(state, value: string) {
    state.phone = value;
  },
  setUserEmail(state, value: string) {
    state.email = value;
  },
  setUserCustomToken(state, value: string) {
    state.customToken = value;
  },
  setUserIdToken(state, value: string) {
    state.idToken = value;
  },
  setUserSignedIn(state, value: boolean) {
    state.userSignedIn = value;
  },
  setUserSignedInPortal(state, value: boolean) {
    state.userSignedInPortal = value;
  },
  setUserIdTokenPortal(state, value: string) {
    state.idTokenPortal = value;
  },
  setUserCustomTokenPortal(state, value: string) {
    state.customTokenPortal = value;
  },
  setUserLastSignIn(state, value: number | string | undefined) {
    if (typeof value === 'undefined') {
      state.lastSignIn = 0;
      return;
    }
    if (typeof value === 'string') {
      state.lastSignIn = new Date(value).getTime();
      return;
    }
    state.lastSignIn = value;
  },
};
