













import Vue from 'vue';

export default Vue.extend({
  name: 'VideoPlayer',
  components: {},
  props: {
    url: {
      type: String,
      default: 'https://player.vimeo.com/video/719120692?h=ab9e62c6eb',
    },
    height: {
      type: Number,
      default: 432,
    },
    width: {
      type: Number,
      default: 768,
    },
  },
  beforeCreate() {
    const script = document.createElement('script');
    script.src = '"https://player.vimeo.com/api/player.js';
  },
});
