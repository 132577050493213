import axios from 'axios';
import { ActionTree } from 'vuex';
import {
  collection, firestorePdrTeam, doc, getDoc, where, query, getDocs,
} from '@/firebase';
import { backendUrl } from '@/store/shared';
import { RootState } from '../types';
import { KnowledgeState } from './types';

const tmp = 0;

const actions: ActionTree<KnowledgeState, RootState> = {
  async fetchAlgoliaSearchKey({ getters, commit, dispatch }) {
    const headers = await getters.getAxiosConfig;
    axios
      .get(`${backendUrl}/getAlgoliaSearchKey`, headers)
      .then((response) => {
        // console.log(response);
        commit('setAlgoliaSearchKey', response.data.key);
        commit('setDashboardLoading', false);
      })
      .catch((error) => {
        console.error(error);
        dispatch('showSnackbarError', 'Während Ihrer Anfrage ist ein Fehler aufgetreten');
        commit('setDashboardLoading', false);
      });
  },
  displayKnowledgeArticle({ commit }, item: Record<string, any>) {
    commit('setKnowledgeDisplayArticle', false);
    commit('setKnowledgeArticleToDisplay', item);
    commit('setKnowledgeDisplayArticle', true);
  },
  async fetchBookmarkedArticles({ getters, commit }, payload: Record<string, any>) {
    const bookmarkedIds: Record<string, any> = getters.getUserBookmarkedArticlesId;
    Object.entries(bookmarkedIds).forEach((value) => {
      const docRef = doc(firestorePdrTeam, 'clientKnowledge', value[0]);
      getDoc(docRef)
        .then((docSnapshot) => {
          commit('addArticle', {
            id: docSnapshot.id,
            data: docSnapshot.data(),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  async fetchKnowledgeTopics({ commit }) {
    const newsRef = collection(firestorePdrTeam, 'knowledgeTopics');
    const q = query(newsRef, where('deleted', '==', false));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      // console.log('article topicd');
      // console.log(document.id, ' => ', document.data());
      commit('addKnowledgeTopic', {
        id: document.id,
        data: document.data(),
      });
    });
  },
  async fetchTopicArticles({ commit }, topicId: string) {
    commit('addKnowledgeTopicIsLoading', { id: topicId, data: true });
    const articlesRef = collection(firestorePdrTeam, 'clientKnowledge');
    const q = query(articlesRef, where('deleted', '==', false), where('topicId', '==', topicId));
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((document) => {
        // console.log('article topicd');
        // console.log(document.id, ' => ', document.data());
        commit('addArticle', {
          id: document.id,
          data: document.data(),
        });
      });
      commit('addKnowledgeTopicIsLoading', { id: topicId, data: false });
    } catch (error) {
      console.error(error);
      commit('addKnowledgeTopicIsLoading', { id: topicId, data: false });
    }
  },
  async fetchAllArticles({ getters, dispatch }) {
    const topics = getters.getKnowledgeTopics;
    Object.entries(topics).forEach((value) => {
      dispatch('fetchTopicArticles', value[0]);
    });
  },
  structureKnowledgeArticles({ getters }) {
    const topics: Record<string, any> = getters.getKnowledgeTopics;
    const structuredTopics: Array<Record<string, any>> = [];
    // Object.entries(topics).forEach()
  },
  async fetchArticle({ commit }, id: string) {
    const docRef = doc(firestorePdrTeam, 'clientKnowledge', id);
    getDoc(docRef)
      .then((docSnapshot) => {
        commit('addArticle', {
          id: docSnapshot.id,
          data: docSnapshot.data(),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export { actions, tmp };
