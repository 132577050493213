import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/knowledge/getters';
import { mutations } from '@/store/knowledge/mutations';
import { actions } from '@/store/knowledge/actions';
import { KnowledgeState } from '@/store/knowledge/types';

const state: KnowledgeState = {
  algoliaSearchKey: '',
  displayArticle: false,
  articleToDisplay: {},
  articles: {},
  articleTopics: {},
};

export const tmp = 0;

export const knowledge: Module<KnowledgeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
