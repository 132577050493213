import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { DashboardState } from './types';

const tmp = 0;

const actions: ActionTree<DashboardState, RootState> = {
  showSnackbarError({ commit }, message: string) {
    commit('setSnackbarMessage', message);
    commit('setShowSnackbar', true);
  },
};

export {
  actions,
  tmp,
};
