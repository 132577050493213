





















































import Vue from 'vue';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';

export default Vue.extend({
  name: 'KnowledgeSearchScreen',
  data: () => ({}),
  components: {},
  computed: {
    apiKey: {
      get(): string {
        return this.$store.getters.getAlgoliaSearchKey;
      },
    },
    searchClient: {
      get(): any {
        return algoliasearch(
          'H9N8UBEUS5',
          this.apiKey,
        );
      },
    },
  },
  methods: {
    displayArticle(item: any) {
      this.$store.commit('addArticle', {
        id: item.id,
        data: item,
      });
      this.$router.push(`/article/${item.id}`);
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchAlgoliaSearchKey');
  },
  created() {
    console.log('created');
  },
  updated() {
    if (!this.apiKey) {
      this.$store.dispatch('fetchAlgoliaSearchKey');
    }
  },
});
