import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/news/getters';
import { mutations } from '@/store/news/mutations';
import { actions } from '@/store/news/actions';
import { NewsFeedState } from '@/store/news/types';

const state: NewsFeedState = {
  news: {},
  displayNewsFeedItem: false,
  newsFeedItemToDisplay: '',
};

export const tmp = 0;

export const newsFeed: Module<NewsFeedState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
