




















import Vue from 'vue';
import TicketsListItem from '@/components/TicketsFeed/TicketsListItem.vue';

export default Vue.extend({
  name: 'TicketsFeed',
  components: {
    TicketsListItem,
  },
  computed: {
    tickets: {
      get(): Record<string, any> {
        return this.$store.getters.getAllTickets;
      },
    },
    displayTicket: {
      get(): boolean {
        return this.$store.getters.getDisplayTicket;
      },
    },
  },
  created() {
    this.$store.dispatch('fetchTickets');
  },
});
