













































import Vue from 'vue';

export default Vue.extend({
  name: 'TicketPromptMeta',
  components: {},
  props: {
    createdAt: {
      type: String,
      required: true,
    },
    doneAt: {
      type: String,
      required: true,
    },
    clientUserName: {
      type: String,
      required: true,
    },
    ticketNo: {
      type: Number,
      required: true,
    },
    done: {
      type: Boolean,
      required: true,
    },
  },
});
