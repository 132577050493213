













import Vue from 'vue';
import algoliasearch from 'algoliasearch/lite';
import KnowledgeBaseCategories from '@/components/KnowledgeBase/KnowledgeBaseCategories.vue';

export default Vue.extend({
  name: 'KnowledgeScreen',
  components: {
    KnowledgeBaseCategories,
  },
  computed: {
    apiKey: {
      get(): string {
        return this.$store.getters.getAlgoliaSearchKey;
      },
    },
    searchClient: {
      get(): any {
        return algoliasearch(
          'H9N8UBEUS5',
          this.apiKey,
        );
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchAlgoliaSearchKey');
    this.$store.dispatch('fetchKnowledgeTopics');
  },
});
