





































import Vue from 'vue';
import { DashboardPage } from '@/store/dashboard/types';

export default Vue.extend({
  name: 'QuickActions',
  components: {},
  computed: {
    page: {
      get(): DashboardPage {
        return this.$store.getters.getDashboardPage;
      },
      set(value: DashboardPage) {
        this.$store.commit('setDashboardPage', value);
      },
    },
  },
  methods: {
    newTicket() {
      this.$store.commit('setDisplayNewTicketPrompt', true);
    },
    newFeatureRequest() {
      this.$store.commit('setDisplayNewFeaturePrompt', true);
    },
    goToBookmark() {
      this.$router.push('/bookmark');
    },
    openPage(page: DashboardPage) {
      this.page = page;
    },
  },
});
