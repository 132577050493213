












import Vue from 'vue';

export default Vue.extend({
  name: 'CommentEditor',
  data: () => ({
    comment: '',
  }),
  props: {
    commentText: {
      type: String,
      default: '',
    },
  },
  components: {},
  methods: {
    updateComment() {
      this.$emit('update', this.comment);
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.comment = this.commentText;
  },
});
