
































import Vue from 'vue';
import BookmarkFeed from '@/components/Bookmarks/BookmarkFeed.vue';

export default Vue.extend({
  name: 'BookmarkScreen',
  components: {
    BookmarkFeed,
  },
  computed: {
    articles: {
      get(): Record<string, any> {
        return this.$store.getters.getUserBookmarkedArticlesId;
      },
    },
    videos: {
      get(): Record<string, any> {
        return this.$store.getters.getUserBookmarkedVideosId;
      },
    },
  },
  methods: {
    displayVideo(id: string) {
      this.$router.push(`/video/${id}`);
    },
    displayArticle(id: string) {
      this.$router.push(`/article/${id}`);
    },
  },
  created() {
    this.$store.dispatch('fetchBookmarkedArticles');
  },
});
