import Vue from 'vue';
import { MutationTree } from 'vuex';
import { BookmarksState, BookmarkType } from '@/store/bookmarks/types';

export const tmp = 0;

export const mutations: MutationTree<BookmarksState> = {
  addUserBookmarkedVideo(state, value: Record<string, any>) {
    Vue.set(state.bookmarkedVideos, value.id, value.data);
  },
  deleteUserBookmarkedVideo(state, id: string) {
    Vue.delete(state.bookmarkedVideos, id);
  },
  addUserBookmarkedArticle(state, value: Record<string, any>) {
    Vue.set(state.bookmarkedArticles, value.id, value.data);
  },
  deleteUserBookmarkedArticle(state, id: string) {
    Vue.delete(state.bookmarkedArticles, id);
  },
  setDisplayBookmark(state, value: boolean) {
    state.displayBookmark = value;
  },
  setBookmarkToDisplay(state, value: Record<string, any>) {
    state.bookmarkToDisplay = value;
  },
  setBookmarkToDisplayId(state, value: string) {
    state.bookmarkToDisplayId = value;
  },
  setBookmarkToDisplayType(state, value: BookmarkType) {
    state.bookmarkToDisplayType = value;
  },
};
