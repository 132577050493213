
















import Vue from 'vue';
import DashboardWrapper from '@/components/Dashboard/DadhboardWrapper.vue';
import ErrorScreen from '@/components/ErrorScreen/ErrorScreen.vue';
import { authPdrTeam } from '@/firebase';

export default Vue.extend({
  name: 'Home',
  components: {
    DashboardWrapper,
    ErrorScreen,
  },
  computed: {
    userSignedIn: {
      get(): boolean {
        return this.$store.getters.getUserSignedIn;
      },
    },
    loading: {
      get(): boolean {
        return this.$store.getters.getDashboardLoading;
      },
    },
  },
  created() {
    // console.log(authPdrTeam.currentUser);
    this.$store.commit('setUserSignedIn', authPdrTeam.currentUser != null);
  },
});
