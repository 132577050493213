import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { FeaturesState } from './types';

export const tmp = 0;

export const getters: GetterTree<FeaturesState, RootState> = {
  getAllFeatureRequests(state): Record<string, any> {
    return state.features;
  },
  getFeatureRequestsEntry(state) {
    return (id: string): any => (
      state.features[id]
    );
  },
  getDisplayFeature(state): boolean {
    return state.displayFeature;
  },
  getFeatureToDisplay(state): string {
    return state.featureTodDisplay;
  },
  getDisplayNewFeaturePrompt(state): boolean {
    return state.displayNewFeaturePrompt;
  },
  getNewFeaturSending(state): boolean {
    return state.newFeatureSending;
  },
  getNewFeatureTopic(state): string {
    return state.newFeatureTopic;
  },
  getNewFeatureInfo(state): string {
    return state.newFeatureInfo;
  },
  getNewFeatureAttachments(state): File[] {
    return state.newFeatureAttachments;
  },
  getFeatureAttachmentToDisplay(state): string {
    return state.featureAttachmentToDisplay;
  },
  getDisplayFeatureAttachment(state): boolean {
    return state.displayFeatureAttachment;
  },
  getFeatureAccountVote(state) {
    return (id: string): any => (
      state.accountVotes[id]
    );
  },
  getFeatureAccountVotes(state): Record<string, any> {
    return state.accountVotes;
  },
};
