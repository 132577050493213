import axios from 'axios';
import { ActionTree } from 'vuex';
import {
  firestorePdrTeam, collection, where, query, getDocs, orderBy, doc, getDoc,
} from '@/firebase';
import { backendUrl, uploadFiles } from '@/store/shared';
import { RootState } from '../types';
import { FeaturesState } from './types';

const tmp = 0;

const actions: ActionTree<FeaturesState, RootState> = {
  async fetchFeatureRequests({ commit, getters }) {
    const featuresRef = collection(firestorePdrTeam, 'featureRequests');
    const q = query(
      featuresRef,
      // where('status', '==', 'inDiscussion'),
      where('visibleForAll', '==', true),
      where('deleted', '==', false),
      orderBy('votes', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnapshot) => {
      // console.log(doc.id, ' => ', doc.data());
      commit('addFeatureRequestEntry', {
        id: docSnapshot.id,
        data: docSnapshot.data(),
      });
    });
    // fetch user features
    const accountId: string = getters.getUserAccountId;
    const q2 = query(
      featuresRef,
      where('clientAccountId', '==', accountId),
      where('deleted', '==', false),
      orderBy('createdAtUnix', 'desc'),
    );
    const querySnapshot2 = await getDocs(q2);
    querySnapshot2.forEach((docSnapshot) => {
      // console.log('user feature', doc.id, ' => ', doc.data());
      commit('addFeatureRequestEntry', {
        id: docSnapshot.id,
        data: docSnapshot.data(),
      });
    });
  },
  async fetchFeatureRequest({ commit }, id: string) {
    const docRef = doc(firestorePdrTeam, 'featureRequests', id);
    getDoc(docRef)
      .then((docSnapshot) => {
        commit('addFeatureRequestEntry', {
          id: docSnapshot.id,
          data: docSnapshot.data(),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async fetchFeatureAccountVote({ commit, getters }, featureId: string) {
    const votesRef = collection(firestorePdrTeam, `featureRequests/${featureId}/votes`);
    const q = query(
      votesRef,
      where('clientAccountId', '==', getters.getUserAccountId),
      where('deleted', '==', false),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnapshot) => {
      console.log('vote');
      // console.log(doc.id, ' => ', doc.data());
      commit('addFeatureAccountVote', {
        id: featureId,
        data: docSnapshot.data(),
      });
    });
  },
  async sendFeatureRequest({ getters, commit, dispatch }, payload: any) {
    commit('setNewFeaturSending', true);
    const attachments = await uploadFiles(
      payload.attachments,
      getters.getUserUid,
      getters.getUserAccountId,
    );
    const headers = await getters.getAxiosConfig;
    console.log(payload);
    axios.post(`${backendUrl}/featureRequestApi/featureRequest`, {
      attachments,
      topic: payload.topic,
      info: payload.info,
    }, headers)
      .then((response) => {
        // console.log(response);
        commit(
          'setSnackbarMessage',
          'Feature-Wunsch wurde verschickt, wir werden uns bald mit Ihnen in Verbindung setzen',
        );
        commit('setNewFeaturSending', false);
        commit('setDisplayNewFeaturePrompt', false);
        commit('setShowSnackbar', true);
      })
      .catch((error) => {
        console.log(error);
        commit('setNewFeaturSending', false);
        commit('setDisplayNewFeaturePrompt', false);
        dispatch('showSnackbarError', 'Während Ihrer Anfrage ist ein Fehler aufgetreten');
      });
  },
  async upVoteFeatureRequest({ getters, commit, dispatch }, id: string) {
    commit('setNewFeaturSending', true);
    const headers = await getters.getAxiosConfig;
    axios.post(`${backendUrl}/featureRequestApi/featureRequest/${id}/vote`, {}, headers)
    // axios.post(`${backendUrl}/featureRequest/${id}/vote`, {}, headers)
      .then((response) => {
        // console.log(response);
        commit(
          'setSnackbarMessage',
          'Feature-Wunsch Vote wurde verschickt',
        );
        commit('setNewFeaturSending', false);
        commit('setDisplayNewFeaturePrompt', false);
        commit('setShowSnackbar', true);
      })
      .catch((error) => {
        commit('setNewFeaturSending', false);
        commit('setDisplayNewFeaturePrompt', false);
        dispatch('showSnackbarError', 'Während Ihrer Anfrage ist ein Fehler aufgetreten');
      });
  },
  async downVoteFeatureRequest({ getters, commit, dispatch }, id: string) {
    commit('setNewFeaturSending', true);
    const headers = await getters.getAxiosConfig;
    // axios.delete(`${backendUrl}/featureRequest/${id}/vote`, headers)
    axios.delete(`${backendUrl}/featureRequestApi/featureRequest/${id}/vote`, headers)
      .then((response) => {
        // console.log(response);
        commit(
          'setSnackbarMessage',
          'Feature-Wunsch Vote wurde verschickt',
        );
        commit('addFeatureAccountVote', { id, data: undefined });
        commit('setNewFeaturSending', false);
        commit('setDisplayNewFeaturePrompt', false);
        commit('setShowSnackbar', true);
      })
      .catch((error) => {
        commit('setNewFeaturSending', false);
        commit('setDisplayNewFeaturePrompt', false);
        dispatch('showSnackbarError', 'Während Ihrer Anfrage ist ein Fehler aufgetreten');
      });
  },
};

export {
  actions,
  tmp,
};
