import { render, staticRenderFns } from "./TicketPromptMeta.vue?vue&type=template&id=471f2fbc&scoped=true&"
import script from "./TicketPromptMeta.vue?vue&type=script&lang=ts&"
export * from "./TicketPromptMeta.vue?vue&type=script&lang=ts&"
import style0 from "./TicketPromptMeta.vue?vue&type=style&index=0&id=471f2fbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "471f2fbc",
  null
  
)

export default component.exports