





















































































































































import Vue from 'vue';
import { DashboardPage } from '@/store/dashboard/types';

export default Vue.extend({
  name: 'TheAppBar',
  computed: {
    toggleDrawer: {
      get(): boolean {
        return this.$store.getters.getDashboardToggleDrawer;
      },
      set(value: boolean) {
        this.$store.commit('setDashboardToggleDrawer', value);
      },
    },
    page: {
      get(): DashboardPage {
        return this.$store.getters.getDashboardPage;
      },
      set(value: DashboardPage) {
        this.$store.commit('setDashboardPage', value);
      },
    },
  },
  methods: {
    toggle() {
      this.toggleDrawer = !this.toggleDrawer;
    },
    newTicket() {
      this.$store.commit('setDisplayNewTicketPrompt', true);
    },
    newFeatureRequest() {
      this.$store.commit('setDisplayNewFeaturePrompt', true);
    },
    openPage(page: DashboardPage) {
      this.page = page;
      this.toggleDrawer = false;
    },
    goHome() {
      this.$router.push('/');
    },
    goToKnowledge() {
      this.$router.push('/knowledge');
    },
    goToNews() {
      this.$router.push('/news');
    },
    goToTickets() {
      this.$router.push('/ticket');
    },
    goToFeatures() {
      this.$router.push('/feature');
    },
    goToVideo() {
      this.$router.push('/video');
    },
  },
});
