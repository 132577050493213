





































import Vue from 'vue';

export default Vue.extend({
  name: 'FeatureRequestItem',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    chipItem: {
      type: String,
      default: 'votes',
    },
    chipColor: {
      type: String,
      default: 'green',
    },
  },
  computed: {
    createdAt: {
      get(): string {
        return new Date(this.item.createdAtUnix).toLocaleDateString('de-DE');
      },
    },
    displayFeature: {
      get(): boolean {
        return this.$store.getters.getDisplayFeature;
      },
      set(value: boolean) {
        this.$store.commit('setDisplayFeature', value);
      },
    },
    featureToDisplay: {
      get(): string {
        return this.$store.getters.getFeatureToDisplay;
      },
      set(value: string) {
        this.$store.commit('setFeatureToDisplay', value);
      },
    },
  },
  methods: {
    showFeature() {
      this.$router.push(`/feature/${this.itemId}`);
      // this.featureToDisplay = this.itemId;
      // this.displayFeature = true;
    },
  },
});
