import { MutationTree } from 'vuex';
import { DashboardState } from '@/store/dashboard/types';

export const tmp = 0;

export const mutations: MutationTree<DashboardState> = {
  setDashboardPage(state, value: 'news' | 'tickets' | 'news' | 'knowledge' | 'videos' | 'bookmarks') {
    state.page = value;
  },
  setDashboardToggleDrawer(state, value: boolean) {
    state.toggleDrawer = value;
  },
  setShowSnackbar(state, value: boolean) {
    state.showSnackbar = value;
  },
  setSnackbarMessage(state, value: string) {
    state.snackbarMessage = value;
  },
  setDashboardLoading(state, value: boolean) {
    state.dashboardLoading = value;
  },
};
