











































import Vue from 'vue';
import { marked } from 'marked';

export default Vue.extend({
  name: 'ArticleScreen',
  computed: {
    articleTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getArticle(this.$route.params.articleId);
      },
    },
    createdAt: {
      get(): string {
        return new Date(
          this.articleTodisplay.visibleFromUnix
            ? this.articleTodisplay.visibleFromUnix : this.articleTodisplay.createdAtUnix,
        ).toLocaleDateString('de-DE');
      },
    },
    convertedHtml: {
      get(): string {
        const options: marked.MarkedOptions = {
          breaks: true,
          gfm: true,
          xhtml: true,
        };
        return marked.parse(this.articleTodisplay.html, options);
      },
    },
    bookmarked: {
      get(): boolean {
        return this.$store.getters.isArticleBookmarked(this.articleTodisplay.id);
      },
    },
  },
  methods: {
    bookmarkArticle() {
      if (this.bookmarked) {
        this.$store.dispatch('unbookmarkArticle', this.$route.params.articleId);
        return;
      }
      this.$store.dispatch('bookmarkArticle', this.$route.params.articleId);
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchArticle', this.$route.params.articleId);
  },
});
