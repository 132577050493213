

































import Vue from 'vue';
import HomeScreen from '@/components/Home/HomeScreen.vue';
import TicketsFeed from '@/views/ticket/TicketsFeed.vue';
import NewsFeed from '@/views/news/NewsFeed.vue';
import FeatureRequestFeed from '@/views/feature/FeatureRequestFeed.vue';
import NewTicketPrompt from '@/components/TicketsFeed/NewTicketPrompt.vue';
import NewFeatureRequestPrompt from '@/components/FeatureRequestFeed/NewFeatureRequestPrompt.vue';
import KnowledgeBaseSearchScreen from '@/components/KnowledgeBase/KnowledgeBaseSearchScreen.vue';
import KnowledgeBaseCategories from '@/components/KnowledgeBase/KnowledgeBaseCategories.vue';
import VideosFeed from '@/views/video/VideosFeed.vue';
import BookmarkScreen from '@/views/bookmarks/BookmarkScreen.vue';
import { DashboardPage } from '@/store/dashboard/types';

export default Vue.extend({
  name: 'DashboardWrapper',
  components: {
    HomeScreen,
    TicketsFeed,
    NewsFeed,
    FeatureRequestFeed,
    NewTicketPrompt,
    NewFeatureRequestPrompt,
    KnowledgeBaseSearchScreen,
    KnowledgeBaseCategories,
    VideosFeed,
    BookmarkScreen,
  },
  computed: {
    page: {
      get(): DashboardPage {
        return this.$store.getters.getDashboardPage;
      },
    },
  },
});
