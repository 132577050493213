









































import Vue from 'vue';

export default Vue.extend({
  name: 'TicketsListItem',
  props: {
    ticket: {
      type: Object,
      default: () => ({
        topic: 'test ticket',
        clientInfo: 'lorem jkbrviub iuiuhoi oihoijoi oihnoihn',
      }),
    },
    ticketId: {
      type: String,
      required: true,
    },
  },
  computed: {
    createdAt: {
      get(): string {
        return new Date(this.ticket.createdAtUnix).toLocaleString('de-DE');
      },
    },
    chipColor: {
      get(): string {
        return this.ticket.done ? 'green' : 'orange';
      },
    },
  },
  methods: {
    displayTicket() {
      this.$router.push(`ticket/${this.ticketId}`);
      // this.$store.dispatch('displayTicket', this.ticketId);
    },
  },
});
