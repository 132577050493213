import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import KnowledgeScreen from '@/views/knowledge/KnowledgeScreen.vue';
import KnowledgeSearchScreen from '@/views/knowledge/KnowledgeSearchScreen.vue';
import KnowledgeArticlesList from '@/views/knowledge/KnowledgeArticlesList.vue';
import ArticleScreen from '@/views/knowledge/ArticleScreen.vue';
import VideosFeed from '@/views/video/VideosFeed.vue';
import VideoScreen from '@/views/video/VideoScreen.vue';
import TicketsFeed from '@/views/ticket/TicketsFeed.vue';
import TicketScreen from '@/views/ticket/TicketScreen.vue';
import NewsFeed from '@/views/news/NewsFeed.vue';
import NewsScreen from '@/views/news/NewsScreen.vue';
import FeatureRequestFeed from '@/views/feature/FeatureRequestFeed.vue';
import UserFeatureRequestFeed from '@/views/feature/UserFeatureRequestFeed.vue';
import FeatureScreen from '@/views/feature/FeatureScreen.vue';
import BookmarkScreen from '@/views/bookmarks/BookmarkScreen.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/news',
    name: 'News',
    component: NewsFeed,
  },
  {
    path: '/news/:newsId',
    name: 'NewsItem',
    component: NewsScreen,
  },
  {
    path: '/video',
    name: 'Videos',
    component: VideosFeed,
  },
  {
    path: '/video/:videoId',
    name: 'Video',
    component: VideoScreen,
  },
  {
    path: '/feature',
    name: 'Features',
    component: FeatureRequestFeed,
  },
  {
    path: '/feature/:featureId',
    name: 'Feature',
    component: FeatureScreen,
  },
  {
    path: '/myFeatures',
    name: 'MyFeatures',
    component: UserFeatureRequestFeed,
  },
  {
    path: '/ticket',
    name: 'Tickets',
    component: TicketsFeed,
  },
  {
    path: '/ticket/:ticketId',
    name: 'Ticket',
    component: TicketScreen,
  },
  {
    path: '/bookmark',
    name: 'Bookmark',
    component: BookmarkScreen,
  },
  {
    path: '/knowledge',
    name: 'Knowledge',
    component: KnowledgeScreen,
  },
  {
    path: '/knowledge/search',
    name: 'KnowledgeSearch',
    component: KnowledgeSearchScreen,
  },
  {
    path: '/knowledge/:topicId',
    name: 'KnowledgeCategory',
    component: KnowledgeArticlesList,
  },
  {
    path: '/article/:articleId',
    name: 'ArticleScreen',
    component: ArticleScreen,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
