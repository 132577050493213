import { AxiosRequestConfig } from 'axios';
import { GetterTree } from 'vuex';
import { authPdrTeam } from '@/firebase';
import { RootState } from '../types';
import { UserState } from './types';

export const tmp = 0;

export const getters: GetterTree<UserState, RootState> = {
  getUserUid(state): string {
    return state.uid;
  },
  getUserSignedIn(state): boolean {
    return state.userSignedIn;
  },
  isUserInfoEmpty(state): boolean {
    if (state.name === undefined || state.phone === undefined || state.email === undefined) {
      return true;
    }
    if (state.name.length === 0 || state.phone.length === 0 || state.email.length === 0) {
      return true;
    }
    return false;
  },
  getUserName(state): string {
    return state.name;
  },
  getUserPhone(state): string {
    return state.phone;
  },
  getUserEmail(state): string {
    return state.email;
  },
  getUserAccountId(state): string {
    return state.accountId;
  },
  getUserCustomToken(state): string {
    return state.customToken;
  },
  async getAxiosConfig(): Promise<AxiosRequestConfig> {
    const config: AxiosRequestConfig = {
      headers: {
        // Authorization: `Bearer ${state.idToken}`,
        Authorization: `Bearer ${await authPdrTeam.currentUser?.getIdToken(true)}`,
      },
    };
    return config;
  },
  getUserIdToken(state): string {
    return state.idToken;
  },
  getUserIdTokenPortal(state): string {
    return state.idTokenPortal;
  },
  getUserCustomTokenPortal(state): string {
    return state.customTokenPortal;
  },
  getUserSignedInPortal(state): boolean {
    return state.userSignedInPortal;
  },
  getUserLastSignIn(state): number {
    return state.lastSignIn;
  },
};
