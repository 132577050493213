



























import Vue from 'vue';
import WelcomeBanner from '@/components/Home/WelcomeBanner.vue';
import RecentNewsBox from '@/components/RecentItems/RecentNewsBox.vue';
import RecentTicketsBox from '@/components/RecentItems/RecentTicketsBox.vue';
import RecentVideosBox from '@/components/RecentItems/RecentVideosBox.vue';

export default Vue.extend({
  name: 'HomeScreen',
  components: {
    WelcomeBanner,
    RecentNewsBox,
    RecentTicketsBox,
    RecentVideosBox,
  },
  computed: {},
});
