import { render, staticRenderFns } from "./CommentBoxContent.vue?vue&type=template&id=4ef7e869&scoped=true&"
import script from "./CommentBoxContent.vue?vue&type=script&lang=ts&"
export * from "./CommentBoxContent.vue?vue&type=script&lang=ts&"
import style0 from "./CommentBoxContent.vue?vue&type=style&index=0&id=4ef7e869&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef7e869",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
installComponents(component, {VAvatar})
