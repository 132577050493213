
























import Vue from 'vue';
import CommentBox from '@/components/Comments/CommentBox.vue';
import CommentTextBox from '@/components/Comments/CommentTextBox.vue';

export default Vue.extend({
  name: 'CommentsFeed',
  props: {
    parentId: {
      type: String,
      required: true,
    },
    parentType: {
      type: String,
      default: 'ticket',
    },
    col: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    comments: {
      get(): Record<string, any> {
        if (this.parentType === 'ticket') {
          return this.$store.getters.getAllTicketComments(this.parentId);
        }
        if (this.parentType === 'video') {
          return this.$store.getters.getAllVideoComments(this.parentId);
        }
        return this.$store.getters.getAllFeatureComments(this.parentId);
      },
    },
  },
  components: {
    CommentBox,
    CommentTextBox,
  },
});
