































import Vue from 'vue';
import { marked } from 'marked';

export default Vue.extend({
  name: 'NewsFeedItem',
  props: {
    newsItem: {
      type: Object,
      required: true,
    },
    newsItemId: {
      type: String,
      required: true,
    },
  },
  computed: {
    createdAt: {
      get(): string {
        return new Date(this.newsItem.visibleFromUnix
          ? this.newsItem.visibleFromUnix : this.newsItem.createdAtUnix).toLocaleDateString('de-DE');
      },
    },
    convertedHtml: {
      get(): string {
        return marked.parse(this.newsItem.info);
      },
    },
    chipColor: {
      get(): string {
        return this.newsItem.type === 'general' ? 'grey light' : 'green';
      },
    },
  },
  methods: {
    displayNewsItem() {
      // this.$store.dispatch('displayNewsFeedItem', this.newsItemId);
      this.$router.push(`/news/${this.newsItemId}`);
      console.log('display news');
    },
  },
});
