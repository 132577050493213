




























import Vue from 'vue';

export default Vue.extend({
  name: 'ImageWrapper',
  props: {
    image: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    displayImg() {
      console.log('display img');
      this.$store.commit('setAttachmentToDisplay', this.image);
      this.$store.commit('setDisplayAttachment', true);
    },
  },
});
