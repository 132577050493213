


























import Vue from 'vue';
import VideoCard from '@/components/Videos/VideoCard.vue';

export default Vue.extend({
  name: 'VideosFeed',
  components: {
    VideoCard,
  },
  computed: {
    videos: {
      get(): Record<string, any> {
        return this.$store.getters.getAllVideos;
      },
    },
    displayVideo: {
      get(): boolean {
        return this.$store.getters.getDisplayVideo;
      },
    },
  },
});
