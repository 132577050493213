















































import Vue from 'vue';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';
import KnowledgeArticlePrompt from '@/components/KnowledgeBase/KnowledgeArticlePrompt.vue';

export default Vue.extend({
  name: 'KnowledgeBaseSearchScreen',
  data: () => ({}),
  components: {
    KnowledgeArticlePrompt,
  },
  computed: {
    apiKey: {
      get(): string {
        return this.$store.getters.getAlgoliaSearchKey;
      },
    },
    searchClient: {
      get(): any {
        return algoliasearch(
          'H9N8UBEUS5',
          this.apiKey,
        );
      },
    },
    articleTodisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getKnowledgeArticleToDisplay;
      },
    },
  },
  methods: {
    displayArticle(item: any) {
      console.log('display article');
      console.log(item);
      this.$store.dispatch('displayKnowledgeArticle', item);
    },
  },
  created() {
    console.log('created');
  },
});
