import { ActionTree } from 'vuex';
import {
  firestorePdrTeam, collection, where, query, getDocs, orderBy,
} from '@/firebase';
import { RootState } from '../types';
import { VideosState } from './types';

const tmp = 0;

const actions: ActionTree<VideosState, RootState> = {
  async fetchVideos({ commit }) {
    const newsRef = collection(firestorePdrTeam, 'videos');
    const q = query(
      newsRef,
      where('visible', '==', true),
      orderBy('createdAtUnix', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.id, ' => ', doc.data());
      commit('addVideo', {
        id: doc.id,
        data: doc.data(),
      });
    });
  },
  displayVideo({ commit }, video: string) {
    commit('setVideoToDisplay', video);
    commit('setDisplayVideo', true);
  },
};

export {
  actions,
  tmp,
};
