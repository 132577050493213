




































































































import Vue from 'vue';
import { DashboardPage } from '@/store/dashboard/types';

export default Vue.extend({
  name: 'TheMenu',
  data: () => ({
    collapse: false,
  }),
  components: {},
  computed: {
    username: {
      get(): string {
        return this.$store.getters.getUserName;
      },
    },
    userEmail: {
      get(): string {
        return this.$store.getters.getUserEmail;
      },
    },
    page: {
      get(): DashboardPage {
        return this.$store.getters.getDashboardPage;
      },
      set(value: DashboardPage) {
        this.$store.commit('setDashboardPage', value);
      },
    },
    toggleDrawer: {
      get(): boolean {
        return this.$store.getters.getDashboardToggleDrawer;
      },
      set(value: boolean) {
        this.$store.commit('setDashboardToggleDrawer', value);
      },
    },
  },
  methods: {
    collapseBar() {
      this.collapse = !this.collapse;
    },
    openPage(page: DashboardPage) {
      this.page = page;
      this.toggleDrawer = false;
    },
    goToHome() {
      this.$router.push('/');
    },
    goToKnowledge() {
      this.$router.push('/knowledge');
    },
    goToNews() {
      this.$router.push('/news');
    },
    goToTickets() {
      this.$router.push('/ticket');
    },
    goToFeatures() {
      this.$router.push('/feature');
    },
    goToVideo() {
      this.$router.push('/video');
    },
    goToBookmark() {
      this.$router.push('/bookmark');
    },
  },
});
