import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/dashboard/getters';
import { mutations } from '@/store/dashboard/mutations';
import { actions } from '@/store/dashboard/actions';
import { DashboardState } from '@/store/dashboard/types';

const state: DashboardState = {
  page: 'home',
  toggleDrawer: false,
  showSnackbar: false,
  snackbarMessage: '',
  dashboardLoading: true,
};

export const tmp = 0;

export const dashboard: Module<DashboardState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
