
























import Vue from 'vue';
import ImageWrapper from '@/components/UI/ImageWrapper.vue';
import ImageOverlay from '@/components/UI/ImageOverlay.vue';

export default Vue.extend({
  name: 'ImagesContainer',
  components: {
    ImageWrapper,
    ImageOverlay,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    styleElements: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    col: {
      type: Number,
      default: 1,
    },
  },
});
