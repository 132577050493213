import Vue from 'vue';
import { MutationTree } from 'vuex';
import { VideosState } from '@/store/videos/types';

export const tmp = 0;

export const mutations: MutationTree<VideosState> = {
  addVideo(state, value: Record<string, any>) {
    Vue.set(state.videos, value.id, value.data);
  },
  setVideoToDisplay(state, value: string) {
    state.videoToDisplay = value;
  },
  setDisplayVideo(state, value: boolean) {
    state.displayVideo = value;
  },
};
