import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DashboardState, DashboardPage } from './types';

export const tmp = 0;

export const getters: GetterTree<DashboardState, RootState> = {
  getDashboardPage(state): DashboardPage {
    return state.page;
  },
  getDashboardToggleDrawer(state): boolean {
    return state.toggleDrawer;
  },
  getShowSnackbar(state): boolean {
    return state.showSnackbar;
  },
  getSnackbarMessage(state): string {
    return state.snackbarMessage;
  },
  getDashboardLoading(state): boolean {
    return state.dashboardLoading;
  },
};
