
















import Vue from 'vue';

export default Vue.extend({
  name: 'VideoTags',
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  components: {},
});
