import Vue from 'vue';
import { MutationTree } from 'vuex';
import { FeaturesState } from '@/store/features/types';

export const tmp = 0;

export const mutations: MutationTree<FeaturesState> = {
  addFeatureRequestEntry(state, value: Record<string, any>) {
    Vue.set(state.features, value.id, value.data);
  },
  setDisplayFeature(state, value: boolean) {
    state.displayFeature = value;
  },
  setFeatureToDisplay(state, value: string) {
    state.featureTodDisplay = value;
  },
  setDisplayNewFeaturePrompt(state, value: boolean) {
    state.displayNewFeaturePrompt = value;
  },
  setNewFeaturSending(state, value: boolean) {
    state.newFeatureSending = value;
  },
  setNewFeatureTopic(state, value: string) {
    state.newFeatureTopic = value;
  },
  setNewFeatureInfo(state, value: string) {
    state.newFeatureInfo = value;
  },
  setNewFeatureAttachments(state, value: File[]) {
    state.newFeatureAttachments = value;
  },
  addNewFeatureAttachment(state, value: File) {
    state.newFeatureAttachments.push(value);
  },
  setFeatureAttachmentToDisplay(state, value: string) {
    state.featureAttachmentToDisplay = value;
  },
  setDisplayFeatureAttachment(state, value: boolean) {
    state.displayFeatureAttachment = value;
  },
  addFeatureAccountVote(state, value: Record<string, any>) {
    Vue.set(state.accountVotes, value.id, value.data);
  },
  // removeFeatureAccountVote(state, featureId: string) {
  //   const index = state.accountVotes.indexOf(featureId);
  //   if (index > -1) {
  //     state.accountVotes.splice(index, 1);
  //   }
  // },
};
