

































































import Vue from 'vue';
import { getDownloadURL, ref, storagePdrTeam } from '@/firebase';
import CommentsFeed from '@/components/Comments/CommentsFeed.vue';
import ImagesContainer from '@/components/UI/ImagesContainer.vue';

export default Vue.extend({
  name: 'FeatureScreen',
  components: {
    CommentsFeed,
    ImagesContainer,
  },
  data: () => ({
    timeout: 4000,
    attachmentsUrl: [] as string[],
  }),
  computed: {
    featureToDisplay: {
      get(): Record<string, any> {
        return this.$store.getters.getFeatureRequestsEntry(
          this.featureToDisplayId,
        );
      },
    },
    featureToDisplayId: {
      get(): string {
        return this.$route.params.featureId;
      },
    },
    createdAt: {
      get(): string {
        return new Date(this.featureToDisplay.createdAtUnix).toLocaleDateString(
          'de-DE',
        );
      },
    },
    voteTooltipMessage: {
      get(): string {
        return this.votes[this.featureToDisplayId] === undefined
          ? 'Feature-Wunsch zustimmen'
          : 'Feature-Wunsch abstimmen';
      },
    },
    likeIconColor: {
      get(): string {
        return this.votes[this.featureToDisplayId] === undefined
          ? 'grey'
          : 'green';
      },
    },
    votes: {
      get(): Record<string, any> {
        return this.$store.getters.getFeatureAccountVotes;
      },
    },
  },
  methods: {
    upVote() {
      this.$store.dispatch('upVoteFeatureRequest', this.featureToDisplayId);
    },
    downVote() {
      this.$store.dispatch('downVoteFeatureRequest', this.featureToDisplayId);
    },
    displayImg(img: string) {
      this.$store.commit('setAttachmentToDisplay', img);
      this.$store.commit('setDisplayAttachment', true);
    },
    loadComments() {
      this.$store.dispatch('fetchFeatureComments', this.featureToDisplayId);
    },
    fetchVotes() {
      this.$store.dispatch('fetchFeatureAccountVote', this.featureToDisplayId);
    },
    vote() {
      if (this.votes[this.featureToDisplayId] === undefined) {
        this.upVote();
      } else {
        this.downVote();
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchFeatureRequest', this.$route.params.featureId);
  },
  created() {
    this.loadComments();
    this.fetchVotes();
  },
});
