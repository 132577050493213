
















import Vue from 'vue';
import QuickActions from '@/components/Home/QuickActions.vue';

export default Vue.extend({
  name: 'WelcomeBanner',
  computed: {
    username: {
      get(): string {
        return this.$store.getters.getUserName;
      },
    },
  },
  components: {
    QuickActions,
  },
});
