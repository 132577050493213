


















































import Vue from 'vue';
import RecentItem from '@/components/RecentItems/RecentItem.vue';

export default Vue.extend({
  name: 'RecentVideosBox',
  data: () => ({
    noItemsText: 'Keine neuen Videos',
    newItemsCount: 2,
    header: 'Videos',
  }),
  components: {
    RecentItem,
  },
  props: {
  },
  computed: {
    videos: {
      get(): Record<string, any> {
        return this.$store.getters.getAllVideos;
      },
    },
    newVideos: {
      get(): Record<string, any> {
        return this.getNewVideos();
      },
    },
    lastSignIn: {
      get(): number {
        return this.$store.getters.getUserLastSignIn;
      },
    },
  },
  methods: {
    displayVideo(id: string) {
      this.$router.push(`/videos/${id}`);
      // this.$store.dispatch('displayVideo', id);
    },
    getNewVideos(): Record<string, any> {
      const newNews: Record<string, any> = {};
      const now = new Date();
      now.setDate(now.getDate() - 3);
      Object.entries(this.videos).forEach((value) => {
        if (value[1].createdAtUnix > now) {
          // eslint-disable-next-line prefer-destructuring
          newNews[value[0]] = value[1];
        }
      });
      return newNews;
    },
  },
});
